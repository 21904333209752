import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  faGithub,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

import SEO from '../components/seo';
import Avatar from '../components/avatar';
import Layout from '../components/layout';

import styles from './index.module.css';

const descriptionParagraphs = [
  <p>
    Hi There! <span role="img">👋</span>
  </p>,
  <p>
    I am Dean Abargil, currently working as Software Engineer @{' '}
    <a target="_blank" href="https://www.openweb.com/">
      OpenWeb
    </a>{' '}
    🪄
  </p>,
  <p>
    Skilled in ReactJS, Redux, Fluxible, NextJS, SASS, ProseMirror and Rich Text
    Formatting, NodeJS, RESTfull API’s <span role="img">📚</span>
  </p>,
  <p>
    {' '}
    My colleagues are saying I have solid engineering skills. We LOVE to solve
    complicated problems and design meaningful solutions together{' '}
    <span role="img">👬</span>
  </p>,
  <p>
    With a Bachelor of Computer Science from Ben-Gurion University{' '}
    <span role="img">🎓</span>
  </p>,
];

const Index = () => (
  <Layout>
    <SEO title="Home" />

    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.avatarWrapper}>
          <Avatar />

          <div className={styles.nameAndJob}>
            <h1>Dean Abargil</h1>
            <h3 className={styles.subHeading}>Software Engineer 🧑‍💻</h3>
          </div>
        </div>

        <div className={`${styles.mainBox} ${styles.fadeInUp}`}>
          <div className={styles.description} style={{ textAlign: 'left' }}>
            {descriptionParagraphs}
          </div>
        </div>

        <div className={styles.socialNetworks}>
          <a
            href="mailto:deanabargil@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
          <a
            href="https://github.com/deaBrgil"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a
            href="https://www.linkedin.com/in/dean-abargil-237742145/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a
            href="https://twitter.com/dev_deanab"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          {/* {TODO: Future resume} */}
        </div>
      </div>
    </div>
  </Layout>
);

export default Index;
